import React, {useEffect, useState} from 'react'
import { useNavigate } from 'react-router-dom'
import logo from '../../assets/images/Logo.jpg'
import './header.scss'
import MenuTop from './MenuTop'
import MenuDate from './MenuDate'


import { useSelector } from 'react-redux'
import { isShowEventsSelector } from '../../redux/News/newsSelectors'
import { useDispatch } from 'react-redux'
import { toggleNews } from '../../redux/News/newsAction'
import icon_back from '../../assets/images/icon_back.svg'
import icon_forward from '../../assets/images/icon-forward-arrow.svg'
import {isLoadingSelector} from "../../redux/GeoJson/geoJsonSelectors";



export const Header = ({ mapRef }) => {
  const [burgerActive, setBurgerActive] = useState(false)
  const navigate = useNavigate()


  const dispatch = useDispatch()
  const isShowEvents = useSelector(isShowEventsSelector)

  const isLoading = useSelector(isLoadingSelector)

  useEffect(()=>{
    console.log(isLoading)
  },[isLoading])


  const isOpenDatePicker=useSelector((state)=>state.ui.isOpenDatePicker)


  const hideNews = () => {
    dispatch(toggleNews())
    const listEventsContainer = document.querySelector(
      '.list-events__container'
    )
    const listEvents = document.querySelector('.list-events')
    const headerEvents = document.querySelector('.list-events h3')

    isShowEvents
      ? (listEventsContainer.style.opacity = '0')
      : (listEventsContainer.style.opacity = '1')
    isShowEvents
      ? (listEvents.style.pointerEvents = 'none')
      : (listEvents.style.pointerEvents = 'all')
    isShowEvents
      ? (listEvents.style.background = 'transparent')
      : (listEvents.style.background = '#f9f9f9')
    isShowEvents
      ? (headerEvents.style.opacity = '0')
      : (headerEvents.style.opacity = '1')
  }

  return (
    <header className='header'>
      <div className='header__container'>
        <div className='header__icon'>
          <div className='header__logo'>
            <img src={logo} alt='logo' onClick={() => window.location.href='/ '} />
          </div>
        </div>
        <div className='header__body'>
          <div
            className={
              burgerActive ? 'header__burger active' : 'header__burger'
            }
            onClick={() => setBurgerActive((prev) => !prev)}
          >
            <span />
          </div>
          <MenuTop mapRef={mapRef} setBurgerActive={setBurgerActive} />
          <MenuDate setBurgerActive={setBurgerActive} />
          <nav
            className={burgerActive ? 'header__menu active' : 'header__menu'}
          ></nav>
        </div>
      </div>

      <button style={{display:burgerActive || !isOpenDatePicker? 'none':'block'}} className={'list-events__hide'} onClick={hideNews}>
        <img src={isShowEvents ? icon_forward : icon_back} alt='' />
        Последние события
      </button>

      <div className="wrapper" style={{display:isLoading? 'block':'none'}}>
        <span className="circle circle-1"></span>
        <span className="circle circle-2"></span>
        <span className="circle circle-3"></span>
        <span className="circle circle-4"></span>
        <span className="circle circle-5"></span>
        <span className="circle circle-6"></span>
        <span className="circle circle-7"></span>
        <span className="circle circle-8"></span>
      </div>

    </header>
  )
}
