import React, { useEffect, useState } from 'react'
import './player.scss'
import { useDispatch, useSelector } from 'react-redux'
import { setGeoJson, setStartPlayer } from '../../redux/GeoJson/geoJsonAction'
import {
  filteredDataOnPeriod,
  startPlayerSelector
} from '../../redux/GeoJson/geoJsonSelectors'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBackwardStep,
  faForwardStep,
  faPlay,
  faPause, faForward, faBackward
} from '@fortawesome/free-solid-svg-icons'
import {useParams} from "react-router";
import { isShowEventsSelector} from "../../redux/News/newsSelectors";

export const Player = () => {
  const dispatch = useDispatch()
  const geojsonData = useSelector(filteredDataOnPeriod)
  const startPlayer = useSelector(startPlayerSelector)
  const [progressValue, setProgressValue] = useState(0)
  const [progressIndex, setProgressIndex] = useState(0)

  const [isLoading,setIsLoading]=useState(true);

  const [playerSpeed, setPlayerSpeed]=useState(1000)



  const toggleNews=useSelector(isShowEventsSelector)

  const params=useParams();

  const stepPlayer = geojsonData
    ? +Math.floor((100 / (geojsonData.length - 1)) * 100) / 100
    : 0

  const changeProgressBar = (e) => {

    const value = +e.target.value

    if (value > 100 - stepPlayer) {
      dispatch(setGeoJson(geojsonData[geojsonData.length - 1]))
    }
    else {
      dispatch(setGeoJson(geojsonData[Math.ceil(value / stepPlayer+1)]))
    }
    setProgressValue(value)

    setProgressIndex(Math.ceil(value/stepPlayer))

  }




  const onStart = () => {
    dispatch(setStartPlayer())
  }

  const jumpEnd = () => {

    setProgressValue(100)
    dispatch(setGeoJson(geojsonData[geojsonData.length - 1]))
    setProgressIndex(geojsonData.length - 1)
  }

  const jumpStart = () => {
    setProgressValue(0)
    setProgressIndex(0)
    dispatch(setGeoJson(geojsonData[0]))
  }

  useEffect(() => {
    if (Math.ceil(progressValue) >= 100) {
      dispatch(setStartPlayer(false))
      return
    }
    if (startPlayer) {
      const timer = setTimeout(() => {
        const index = Math.ceil(progressValue / stepPlayer + 1)
        setProgressValue((prev) => prev + stepPlayer)
        dispatch(setGeoJson(geojsonData[index-1]))
        if(index+1 == geojsonData.length){
          console.log('length')
          setProgressValue(100)
        }


        setProgressIndex(index)
      }, playerSpeed)
      return () => clearTimeout(timer)
    }
  }, [progressValue, startPlayer])


  useEffect(() => {

    if(params.type=="battle" && geojsonData){
        jumpEnd()
    }else{
      setProgressValue(0)
      setProgressIndex(0)
    }
    if(geojsonData){
      setIsLoading(false)
    }


  }, [geojsonData])



  // скрываем плеер, если нет данных или даннные за 1 день
  if (!geojsonData || geojsonData.length === 1) {
    return null
  }


  return (
    <div className={'player'} style={{width: toggleNews? '66%':''}}>




      <div className='player__container'>
        <div className='player__control'>
          <div className='player__control__speed'>
            x{(2-(playerSpeed/1000)).toFixed(1)}
          </div>
          <button
            disabled={!geojsonData}
            className={'player__button'}
            onClick={jumpStart}
          >
            <FontAwesomeIcon icon={faBackwardStep} />
          </button>

          <button
              disabled={!geojsonData}
              style={{color:playerSpeed>=1800?'#949494':''}}
              className={'player__button to-speed'}
              onClick={()=>playerSpeed+200<=1800? setPlayerSpeed(prev=>prev+200):setPlayerSpeed(playerSpeed)}
          >
            <FontAwesomeIcon icon={faBackward} />
          </button>




          <button
            disabled={!geojsonData}
            className={'player__button'}
            onClick={onStart}
          >
            {startPlayer && progressValue < 100 ? (
              <FontAwesomeIcon icon={faPause} />
            ) : (
              <FontAwesomeIcon icon={faPlay} />
            )}
          </button>

          <button
              disabled={!geojsonData}
              style={{color:playerSpeed<=200?'#949494':''}}
              className={'player__button to-speed'}
              onClick={()=>playerSpeed-200? setPlayerSpeed(prev=>prev-200):setPlayerSpeed(playerSpeed)}
          >
            <FontAwesomeIcon icon={faForward} />
          </button>


          <button
            disabled={!geojsonData}
            className={'player__button'}
            onClick={jumpEnd}
          >
            <FontAwesomeIcon icon={faForwardStep} />
          </button>
        </div>
        <div className={'player__strip'}>
          <input
            disabled={!geojsonData}
            className={'player__progress progress'}
            type={'range'}
            step={stepPlayer}
            min={0}
            max={100}
            value={progressValue}
            onChange={changeProgressBar}

            style={{
              backgroundImage: `linear-gradient( to right, #F2994A, 
                        #F4C319 ${(100 * +progressValue) / 100}%, 
                        #474747 ${(100 * +progressValue) / 100}%)`
            }}
          />
          {geojsonData &&
            geojsonData.map((item, index) => (
              <div
                className={'player__hint hint'}
                key={index}
                style={{
                  left: index * stepPlayer + '%',
                  color: index === progressIndex ? '#00194c' : 'black'
                }}
              >

                {
                  <p
                    style={{
                      transform:
                        index === progressIndex ? 'scale(1.5)' : 'scale(1)'
                    }}
                  >
                    {index == progressIndex &&
                      new Date(item.date).toLocaleDateString().substring(0, 5)}
                  </p>
                }
                {/*<div className={'hint__line'} />*/}


              </div>
            ))}
        </div>
      </div>
    </div>
  )
}
