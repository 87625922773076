import React, { useEffect, useState } from 'react'
import {
  mapCenterDonbass,
  mapCenterUkraine,
  menuHeaderList
} from '../../utils/Constants'
import axios from 'axios'
import { getBattlePlaceNews, getNews } from '../../redux/News/newsAction'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

import { setSelectedDate } from '../../redux/Date/dataAction'
import { formatDate } from '../../utils/configData'



const MenuTop = ({ mapRef, setBurgerActive }) => {
  const [activeMenuItem, setActiveMenuItem] = useState(1)
  const [activeSubMenuItem, setActiveSubMenuItem] = useState(null)
  const [listBattles, setListBattles] = useState(null)
  const [showSubMenu, setShowSubMenu] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  let params = useParams()

  const showMapCenter = (e) => {
    const index = e.target.dataset.index
    switch (index) {
      case '0':
        mapRef.current.setView(mapCenterUkraine, 6)
        setActiveMenuItem(+e.target.dataset.index)
        break
      case '1':
        mapRef.current.setView(mapCenterDonbass, 7)
        setActiveMenuItem(+e.target.dataset.index)
        break
      default:
        return
    }
    setActiveSubMenuItem(null)
    setBurgerActive(false)
  }

  useEffect(() => {
    if (params.type && params.type === 'battle' && params.id) {
      dispatch(getBattlePlaceNews(params.id))
    }
    else{
      setActiveSubMenuItem(null)
    }
  }, [params.id])



  useEffect(() => {
    if (params.type && params.type === 'news' && params.id) {
      axios(`${process.env.REACT_APP_BASE_URL}news/news?news_id=${params.id}`)

      dispatch(getNews(params.date))
    }
  }, [params.id])

  const toFocusEvent = (e, item) => {

    item.bounds &&
      mapRef.current.fitBounds(JSON.parse(item.bounds), { padding: [50, 50] })
    if (typeof e === 'number') {
      setActiveSubMenuItem(e)
    } else {
      setActiveSubMenuItem(+e.target.dataset.index)
    }

    setBurgerActive(false)

    // navigate(
    //   `/${new Date(item.start_date).toLocaleDateString()}-${new Date(
    //     item.end_date
    //   ).toLocaleDateString()}/battle/${item.id}`
    // )
    navigate(
      `/${new Date(item.start_date).toLocaleDateString()}-${new Date(item.end_date).toLocaleDateString()}/battle/${item.id}`
    )
    dispatch(setSelectedDate(formatDate(new Date())))
    setShowSubMenu(false)

  }

  const toClickOutsideArea = (e) => {
    const sub = document.querySelector('#sub-list-battles')
    const withinBoundaries = e.composedPath().includes(sub)
    if (!withinBoundaries) {
      setShowSubMenu(false)
    }
  }

  useEffect(() => {
    if (listBattles && params.type && params.type === 'battle' && params.id) {
      toFocusEvent(
        +params.id,
        listBattles.find((item) => item.id === +params.id)
      )
    }
  }, [listBattles])

  useEffect(() => {
    async function getData() {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}battle-place/get-battle-places`
        )
        setListBattles(response.data.data)
      } catch (e) {
        setListBattles([{ name: 'Данных нет', bounds: null }])
      }
      document.addEventListener('click', toClickOutsideArea)
    }

    getData()

    return function () {
      document.removeEventListener('click', toClickOutsideArea)
    }
  }, [])

  return (
      <ul className={'menu-top'} data-da='header__menu,0,992'>
        {menuHeaderList.map((item, index) => (
            <li
                key={item.id}
                data-index={index}
                className={
                  index === activeMenuItem
                      ? 'menu-top__item menu-top__item_active'
                      : 'menu-top__item'
                }
                onClick={showMapCenter}
            >
              {item.title}
            </li>
        ))}

        <li
            className={'menu-top__item menu-top__item_battles'}
            id={'sub-list-battles'}

        >
        <span onClick={() => setShowSubMenu((prev) => !prev)}>
          Места сражений
        </span>
          {
            <ul
                style={{ display: showSubMenu ? 'block' : 'none' }}
                className={'menu-top__sub-menu menu-sub'}
            >
              {listBattles &&
              listBattles.map((item, index) => (
                  <li
                      key={index}
                      data-index={item.id}
                      className={
                        activeSubMenuItem === item.id
                            ? 'menu-sub__item active'
                            : 'menu-sub__item'
                      }
                      onClick={(e) => toFocusEvent(e, item)}
                  >
                    {item.name}
                  </li>
              ))}
            </ul>
          }
        </li>
      </ul>
  )
}

export default React.memo(MenuTop)
