
import {
 SET_OPEN_DATE_PICKER
} from './uiConstants'


export const setOpenDatePicker = (isOpen) => ({
  type: SET_OPEN_DATE_PICKER,
  payload: isOpen
})



