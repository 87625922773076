import React, {useEffect, useState} from 'react'
import { currentDate } from '../../utils/Constants'
import { Calendar } from '../Calendar/Calendar'
import { useDispatch, useSelector } from 'react-redux'
import {
  setEndDate,
  setSelectedDate,
  setStartDate
} from '../../redux/Date/dataAction'
import { useNavigate, useParams } from 'react-router-dom'
import { Modal } from '../Modal/Modal'
import { MapLegend } from '../MapLegend/MapLegend'
import { formatDate } from '../../utils/configData'
import { TelegramIcon, VKIcon } from 'react-share'
import logo from '../../assets/images/Logo.jpg'
import {
  faAlignLeft,
  faArrowAltCircleLeft,
  faCalendarAlt,
  faMapLocationDot,
  faQuestionCircle
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { isOpenDatePickerSelector } from '../../redux/Ui/uiSelectors'
import { setOpenDatePicker } from '../../redux/Ui/uiAction'

const MenuDate = ({ setBurgerActive }) => {
  let params = useParams()
  const dispatch = useDispatch()
  const startDate = useSelector((state) => state.date.startDate)
  const endDate = useSelector((state) => state.date.endDate)
  const isOpenDatePicker=useSelector((state)=>state.ui.isOpenDatePicker)
  const startDateParams =
    params.date?.length <= 10
      ? params.date.substring(0, 10).toDate('dd.mm.yyyy', '.')
      : null
  const [activeLegend, setActiveLegend] = useState(false)
  const [activeInfoModal, setActiveInfoModal] = useState(false)
  const navigate = useNavigate()
  const [isOpenCalendar, setIsOpenCalendar] = useState(false)

  const onChangeDatePeriod = (dates) => {
    const [start, end] = dates
    dispatch(setStartDate(start))
    dispatch(setEndDate(end))
    if (start && end) {
      let remainingParams = params.latitude
        ? params.latitude + '/' + params?.longitude + '/' + params?.scale
        : ''
      navigate(`/${formatDate(start)}-${formatDate(end)}/${remainingParams}`)
      setBurgerActive(false)
    }
  }

  const onChangeDateOnly = (dates) => {
    setBurgerActive(false)
    let remainingParams = params.latitude
      ? params.latitude + '/' + params?.longitude + '/' + params?.scale
      : ''
    dispatch(setSelectedDate(formatDate(dates)))
    if (formatDate(dates) !== params.date) {
      navigate(`/${formatDate(dates)}/${remainingParams}`)
    }
  }

  const toClickOutsideArea = (e) => {
    const sub = document.querySelector('#date-selector')
    const sub1 = document.querySelector('#open-calendar')
    const withinBoundaries = e.composedPath().includes(sub)
    const withinBoundaries1 = e.composedPath().includes(sub1)
    if (!withinBoundaries && !withinBoundaries1) {
       if(isOpenCalendar){
         setIsOpenCalendar(prev=>!prev);
         dispatch(setOpenDatePicker(isOpenCalendar))
       }



        document.removeEventListener('click', toClickOutsideArea)
      //setIsOpenCalendar(false)

    }
  }

  useEffect(()=>{
    if(isOpenCalendar){
      document.addEventListener('click', toClickOutsideArea)

    }
    else{
      console.log('delete')
      document.removeEventListener('click', toClickOutsideArea)
    }




  },[isOpenCalendar])

  return (
    <>
      <ul className='menu-date' data-da='header__menu,first,767'>
        <li
          data-da={'header__container,1,767'}
          className={'menu-date__item menu-date__item_question'}
          onClick={() => setActiveInfoModal(true)}
        >
          {/*<div className={'menu-date__icon-info'} title={'Справка'}>?</div>*/}
          <div className={'menu-date__legend'}>
            <FontAwesomeIcon icon={faQuestionCircle} className='fa-2x' />
          </div>
        </li>
        <li
          data-da={'header__container,1,768'}
          className={'menu-date__item menu-date__item_legend'}
          onClick={() => setActiveLegend(true)}
        >
          <div className={'menu-date__legend'}>
            <FontAwesomeIcon icon={faMapLocationDot} className='fa-2x' />
            {/*<img src={iconLegend} alt="icon-legend" />*/}
          </div>
        </li>

        <li className={'menu-date__item menu-date__item_legend'} id="open-calendar">
          <FontAwesomeIcon
            icon={faCalendarAlt}
            className={'cal-big-icon fa-2x menu-date__item_legend'}
            style={{color:isOpenCalendar? 'white':''}}

            onClick={() => {setIsOpenCalendar(prev=>!prev); dispatch(setOpenDatePicker(isOpenCalendar)) }}
          />
        </li>

       
        {(isOpenCalendar || window.innerWidth < 768) && (
          <div id="date-selector" className='menu-date__item-holder arrow'>
            <li
              className='menu-date__item'
              onClick={() => onChangeDateOnly(new Date())}
            ><div>
              <div className='menu-date__title'>Сегодня:</div>
              <span>{formatDate(currentDate)}</span>
            </div>

            </li>
            <li className='menu-date__item'>



              <Calendar
                selected={startDateParams}
                onChange={onChangeDateOnly}
                selectsRange={false}
                title={'Выбранная дата:'}
              />


            </li>

            <li className='menu-date__item'>
              <Calendar
                startDate={startDate}
                endDate={endDate}
                onChange={onChangeDatePeriod}
                selectsRange={true}
                title={'Период:'}
              />
            </li>
          
            {/*<li className='menu-date__item'>*/}
            {/*  <Calendar*/}
            {/*    startDate={startDate}*/}
            {/*    endDate={endDate}*/}
            {/*    onChange={onChangeDatePeriod}*/}
            {/*    selectsRange={true}*/}
            {/*    title={'Период:'}*/}
            {/*  />*/}
            {/*</li>*/}
          </div>
        )}
      </ul>
      <Modal
        setActive={setActiveInfoModal}
        active={activeInfoModal}
        title={'Информация'}
        positionRequire={'static'}
      >
        <div className={'info'}>
          <p className={'info__text'}>Внимание!</p>
          <p className={'info__text'}>
            Администрация сайта не несет ответственности за точность, полноту
            или качество предоставленной информации.
          </p>
          <p className={'info__text'}>
            По требованию Роскомнадзора ООО «Портал» приводит данные о деталях
            военной операции на Украине на основании информации российских
            официальных источников.
          </p>
          <br />
          <p className={'info__links'}>
            <a href={'https://t.me/dnrone'} target={'_blank'}>
              {' '}
              <TelegramIcon size={25} round={true} />
            </a>
            <a href={'https://da-info.pro/'} target={'_blank'}>
              <img className={'info__website-logo'} src={logo} alt='logo' />
            </a>
            <a href={'https://vk.com/da_info_pro'} target={'_blank'}>
              <VKIcon size={25} round={true} />
            </a>
          </p>
        </div>
      </Modal>
      <Modal
        setActive={setActiveLegend}
        active={activeLegend}
        title={'Легенда'}
      >
        <MapLegend />
      </Modal>
    </>
  )
}

export default React.memo(MenuDate)
