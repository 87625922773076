import {
  SET_OPEN_DATE_PICKER
} from './uiConstants'

let initialState = {
  isOpenDatePicker:true
}

export const uiReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_OPEN_DATE_PICKER:
      return {
        ...state,
        isOpenDatePicker: action.payload
      }
    default:
      return state
  }
}
